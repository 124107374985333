<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Events Photo Gallery!  </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'events-gallery'}">Gallery</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row ">
            <!-- The main article section starte here -->
            <div class="col-lg-8 profile-card-5" v-for="obj1 in GallObj" v-bind:key="obj1.id">
                <div class="page-title-text">
                    <h5>{{obj1.egall_tittle}}</h5><br>
                    <!-- <p>{{Data.profile_title}}</p>                 -->
                </div>            
                <div class="container ">
                    <transition-group name="thumbnailfade" tag="div">
                        <img v-for="thumb in images.slice(0, ImageCnt)" 
                        :key="thumb.id" 
                        v-on:click="showLightbox(thumb.name)"  
                        :src="thumbnailDir + thumb.name" 
                        :alt="thumb.alt" 
                        :title="thumb.alt" />
                    </transition-group>     
                    <lightbox id="mylightbox"
                        ref="lightbox"
                        :images="images"
                        :filter="galleryFilter"
                        :directory="thumbnailDir"
                        :timeoutDuration="5000"
                    ></lightbox>             
                </div>   
                <div class="col-md-12 contact-card mt-4 ml-3">
                    <h5>About this Event!</h5>
                    <p class="tab-desc-formatted" v-if='obj1.egall_desc !== ("" || null)' ><span v-html="obj1.egall_desc"></span></p>  
                </div> 
                <div class="col-md-12 contact-card mt-4 ml-3">
                  <GoogleAdsense slot="2761789315"/>
                </div>              
                  
             </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <h3 class="sidebar-title">Recent Event Gallery</h3>
                <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0, 6)" v-bind:key="Data.id">
                    <div class="post-item clearfix mt-4">
                      <router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}"><img class="rounded-circle float-left" v-bind:src="Data.egall_logo" :alt="Data.egall_tittle"></router-link>
                      <h4><router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}">{{Data.egall_tittle}}</router-link></h4>
                      <p class="tag-line">{{Data.egall_desc}}</p>
                    </div>
                </div>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/> 
                <SideRecentPerformers/>
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Lightbox from 'vue-my-photos'
Vue.component('lightbox', Lightbox);
Vue.use(Lightbox)

  export default {
  name:"EventsPhotoGallery",
    //insert the following code for vue-meta to work
    metaInfo() {
        return {
            title: `${this.MetaName} - Events Gallery | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com`,
            meta: [
                { name: 'description', content: this.MetaName + '-' + ' - Events Gallery | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com'},
                { property: 'og:title', content: this.MetaName + ' - ' + ' - Events Gallery | Online Program Booking | Artist Portfolios | Book Stage Shows :: Thiraseela.com'},
                { property: 'og:site_name', content: 'Thiraseela.com'},
                { property: 'og:description', content: 'Thiraseela.com Events Gallery :: ' + this.MetaName + '-'},
                {property: 'og:type', content: 'profile'},
                // {property: 'og:url', content: 'https://thiraseela.com/troupes-detail/' + this.MetaSeqno + '/' + this.MetaName},
                {property: 'og:image', content: this.MetaUrl }    
            ]
        }
    },      
    data(){
      return{
      RecentData: [],
      galleryFilter: 'all',
      ImageCnt: '19',
      thumbnailDir: '',
      // thumbnailDir: 'https://storage.googleapis.com/thiramedia/gallery/61/',
      // images: [],
      images: [
        {'name':'image1.jpeg', 
          'alt':'image1.jpeg', 
          'id':'image1' 
        },
        {'name':'image2.jpeg', 
          'alt':'image2.jpeg', 
          'id':'image2' 
        },
        {'name':'image3.jpeg', 
          'alt':'image3.jpeg', 
          'id':'image3' 
        },
        {'name':'image4.jpeg', 
          'alt':'image4.jpeg', 
          'id':'image4' 
        },
        {'name':'image5.jpeg', 
          'alt':'image5.jpeg', 
          'id':'image5' 
        },
        {'name':'image6.jpeg', 
          'alt':'image6.jpeg', 
          'id':'image6' 
        },
        {'name':'image7.jpeg', 
          'alt':'image7.jpeg', 
          'id':'image7' 
        },
        {'name':'image8.jpeg', 
          'alt':'image8.jpeg', 
          'id':'image8' 
        },
        {'name':'image9.jpeg', 
          'alt':'image9.jpeg', 
          'id':'image9' 
        },
        {'name':'image10.jpeg', 
          'alt':'image10.jpeg', 
          'id':'image10' 
        },
        {'name':'image11.jpeg', 
          'alt':'image11.jpeg', 
          'id':'image11' 
        },
        {'name':'image12.jpeg', 
          'alt':'image12.jpeg', 
          'id':'image12' 
        },
        {'name':'image13.jpeg', 
          'alt':'image13.jpeg', 
          'id':'image13' 
        },
        {'name':'image14.jpeg', 
          'alt':'image14.jpeg', 
          'id':'image14' 
        },
        {'name':'image15.jpeg', 
          'alt':'image15.jpeg', 
          'id':'image15' 
        },
        {'name':'image16.jpeg', 
          'alt':'image16.jpeg', 
          'id':'image16' 
        },
        {'name':'image17.jpeg', 
          'alt':'image17.jpeg', 
          'id':'image17' 
        },
        {'name':'image18.jpeg', 
          'alt':'image18.jpeg', 
          'id':'image18' 
        },
        {'name':'image19.jpeg', 
          'alt':'image19.jpeg', 
          'id':'image19' 
        },          
        ],      
      GallObj: {
        egall_tittle: '',
        egall_desc: '',
        egall_catg: '',
      },
        MetaName: '',
        MetaTagline: '',
        MetaSeqno: '',
        MetaUrl: '',        
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadRecentGallery(to.params.EgallId);
      this.ReadEventsGalleryDetails(this.$route.params.EgallId);
      this.ReadEventsGalleryMainID(this.$route.params.EgallId);
      this.thumbnailDir='https://storage.googleapis.com/thiramedia/gallery/'+this.$route.params.EgallId+'/'   ;   
    }
  },       
    mounted()
    {
      this.ReadEventsGalleryDetails(this.$route.params.EgallId)
      this.ReadEventsGalleryMainID(this.$route.params.EgallId)
      this.thumbnailDir='https://storage.googleapis.com/thiramedia/gallery/'+this.$route.params.EgallId+'/'
      this.ReadRecentGallery();
      // console.log('thumbnailDir::',this.thumbnailDir)
    },
  computed: {
    filteredImages: function() {
      if (this.galleryFilter === 'all') {
        return this.images;
      } else {
        return this.images.filter(image => image.filter === this.galleryFilter);
      }
    }
  },
   methods: {
    showLightbox: function(imageName) {
      this.$refs.lightbox.show(imageName);
    },
      ReadEventsGalleryDetails(UserToFetch){
      const DTLformData = new FormData();
      DTLformData.append('egall_id', UserToFetch);
      // console.warn('DTLformData data', UserToFetch)
      Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadEventsGalleryDetails', DTLformData)
      .then((resp)=>{
      this.images=resp.data.EventsGalleryDetails;
        const FirstObject = resp.data.EventsGalleryDetails[0];
        this.MetaName = FirstObject.egall_tittle;
        // this.MetaTagline = FirstObject.troupe_pgm_name;
        this.MetaSeqno = FirstObject.egall_id;
        this.MetaUrl = FirstObject.egall_photo_dir;          
    })
    },
      ReadEventsGalleryMainID(UserToFetch){
      const MainformData = new FormData();
      MainformData.append('egall_id', UserToFetch);        
      Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadEventsGalleryMainID', MainformData)
      .then((resp)=>{
      this.GallObj=resp.data.EventsGalleryMainID;
      })
      },
      ReadRecentGallery(){
      Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentGallery')
      .then((resp)=>{
      this.RecentData=resp.data.RecentGallery;
      })
      },            
  },
    components: {
      Lightbox
    },
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
img {
  width: 231px;
  height: 150px;
  margin: 1px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.thumbnailfade-leave-active,
.thumbnailfade-enter-active {
  transition: all 0.4s ease;
}

.thumbnailfade-enter-active {
  transition-delay: 0.4s;
}

.thumbnailfade-enter,
.thumbnailfade-leave-to {
  opacity: 0;
}

.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    margin-top:2px;
    font-size:14px;
    font-weight:300;
    padding: 0px 10px;
}
.tab-desc-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 450px;
  overflow: auto;  
  display: inline-block;
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}

.page-title-text p {
    margin-top: -25px;
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}

.contact-card {
    margin-top:25px;
    box-shadow:0 0 1px rgba(0,0,0,0.63);
    border-radius:5px;
    max-width: 696px;
}
.contact-card h5{
    margin-top:30px;
    color:#e75480;
    font-weight:800;
    font-size:16px;
    padding: 15px 5px 0px 10px;
}
.contact-card p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 5px 5px;
}
.contact-card .social-links a {
    font-size: 25px;
    display: inline-block;
    /* background: #1e4356; */
    color: #e75480;
    line-height: 1;
    padding: 12px 0;
    margin-right: 4px;
    margin-bottom:30px;
    border-radius: 50%;
    border: 2px solid #72afce;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}
.contact-card .social-links a:hover {
    background: #e75480;
    color: #fff;
    text-decoration: none;
}
</style>
